import React from "react"
import "../static/css/login.css"

const NotFoundPage = () => (
  <iframe
    className="loginpage"
    src="http://ec2-13-210-247-35.ap-southeast-2.compute.amazonaws.com/login"
    title="ChuteOff Admin Panel"
  ></iframe>
)

export default NotFoundPage
